import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationPor from "./locales/por/translation.json";
import translationEn from "./locales/en/translation.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: true,
        lng: "en",
        fallbackLng: "en",

        keySeparator: false,
        interpolation: {
            escapeValue: false
        },

        resources: {
            pt: {
                translations: translationPor
            },
            en: {
                translations: translationEn
            }
        },

        ns: ["translations"],
        defaultNS: "translations",
        react: {
            transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
            transKeepBasicHtmlNodesFor: ['span'],
        }
    });

export default i18n;