import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./header";

const API_ENDPOINT = API_URL + "/v1/machine";

export function getMachinesList(params) {
  return axios.get(`${API_ENDPOINT}`, { headers: getHeaderOptions(), params: params });
}

export function deleteMachine(id) {
  return axios.delete(`${API_ENDPOINT}/${id}`, { headers: getHeaderOptions() });
}

export function addMachine(data) {
  return axios.post(`${API_ENDPOINT}`, data, { headers: getHeaderOptions() });
}

export function getMachinesById(id) {
  return axios.get(`${API_ENDPOINT}/${id}`, { headers: getHeaderOptions() });
}

export function editMachineById(data, id) {
  return axios.put(`${API_ENDPOINT}/${id}`, data, { headers: getHeaderOptions() });
}

export function deleteMachineAssets(data, id) {
  return axios.put(`${API_ENDPOINT}/delete-document/${id}`, data, { headers: getHeaderOptions() });
}

export function getProductLine(id) {
  return axios.get(`${API_ENDPOINT}/oem-user/${id}`, { headers: getHeaderOptions() });
}

export function newTemplateData(data) {
  return axios.post(`${API_URL}/v1/meeting-report`, data, { headers: getHeaderOptions() });
}

export function EditTemplateData(data, id) {
  return axios.put(`${API_URL}/v1/meeting-report/${id}`, data, { headers: getHeaderOptions() });
}
export function getSelctedTemplateData(id, meetingId) {
  return axios.get(`${API_URL}/v1/meeting-report/${id}${meetingId ? `?meetingId=${meetingId} ` : ""}`, {
    headers: getHeaderOptions(),
  });
}
export function selctedTemplate(id, data) {
  return axios.put(`${API_URL}/v1/meeting/meeting-report/${id}`, data, { headers: getHeaderOptions() });
}
export function selctedOfflineTemplate(id, data) {
  return axios.put(`${API_URL}/v1/meeting/meeting-report/offline/${id}`, data, { headers: getHeaderOptions() });
}

export function getTemplateData(id) {
  return axios.get(`${API_URL}/v1/meeting/report/${id}`, { headers: getHeaderOptions() });
}

export function uploadTemplateCompanyImage(data) {
  return axios.post(`${API_URL}/v1/meeting-report/upload-images`, data, { headers: getHeaderOptions() });
}
export function deleteTemplateCompanyImage(data) {
  return axios.post(`${API_URL}/v1/meeting-report/remove-images`, data, { headers: getHeaderOptions() });
}
export function uploadAttachment(data) {
  return axios.post(`${API_URL}/v1/meeting-report/upload-report-attachments`, data, { headers: getHeaderOptions() });
}

export function deleteUploadedAttachment(data) {
  return axios.post(`${API_URL}/v1/meeting-report/remove-report-attachments`, data, { headers: getHeaderOptions() });
}

export function generatePDF(id) {
  return axios.get(`${API_URL}/v1/meeting-report/html-to-pdf/${id}`, {
    headers: getHeaderOptions(),
    responseType: "blob",
    timeout: 1000 * 60 * 5 
  })}

export function downloadZip(id) {
  return axios.get(`${API_URL}/v1/meeting-report/zip?meetingId=${id}`, {
    headers: getHeaderOptions(),
    responseType: "blob",
  });
}

export function uploadImages(images) {
  return axios.post(`${API_URL}v1/meeting-report/upload-images`, {
    images: images,
    type: "report_images"
  });
}

export function extractFilenameFromHeaders(response) {
  const disposition = response.headers['content-disposition'];
  if (disposition) {
    const filenameRegex = /filename[^;]*=(.*)/;
    const match = filenameRegex.exec(disposition);
    if (match) {
      return match[1].trim(); // Extract and trim the filename
    }
  }
};

