
export const API_URL= process.env.REACT_APP_API_URL
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const  VIDEOPROVIDER = process.env.VIDEOPROVIDER

// export const API_URL = 'https://api.vyzeo.com'
// export const SOCKET_URL = 'https://socket.vyzeo.com'


// export const TWILIO_SDK_ENDPOINT = 'http://localhost:8081'
export const TWILIO_SDK_ENDPOINT = process.env.REACT_APP_TWILIO_SDK_ENDPOINT
export const VONAGE_SDK_ENDPOINT = process.env.REACT_APP_VONAGE_SDK_ENDPOINT

//TEST KEY
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51HhqhIG1jtcn22dqWILxrTIMxFrdfK4tH9X5H3TgCEUQ7o7YPW8hTwk8huQRa47e8XUMrrCE1lqf04wjLbngRvKo00LNeT2oeg'
 