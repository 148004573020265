class Config {
    apiUrl = process.env.REACT_APP_API_URL
    socketUrl = process.env.REACT_APP_SOCKET_URL
    // //apiUrl = "http://18.118.191.62:5056"
    // socketUrl = "https://01tvswln-6058.inc1.devtunnels.ms"
    // apiUrl = "https://api.vyzeo.com"
    // socketUrl = "https://socket.vyzeo.com"
 
}

export default Config;
