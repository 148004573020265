
export function getHeaderOptions() {
    let headerOptions = {
        "Content-Type": 'application/json'
    }
    let token = localStorage.getItem('token');
    if (token) {
        headerOptions = {
            ...headerOptions,
            Authorization: `Bearer ${token}`
        }
    }
    return headerOptions;
}
