// import React, {Fragment} from 'react';
// import {connect} from 'react-redux';
// import cx from 'classnames';
// import {withRouter} from 'react-router-dom';
// import ResizeDetector from 'react-resize-detector';

// import AppMain from '../../Layout/AppMain/appMain';

// class Main extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             closedSmallerSidebar: false
//         };

//     }

//     render() {
//         let {
//             colorScheme,
//             enableFixedHeader,
//             enableFixedSidebar,
//             enableFixedFooter,
//             enableClosedSidebar,
//             closedSmallerSidebar,
//             enableMobileMenu,
//             enablePageTabsAlt,
//         } = this.props;

//         return (
//             <ResizeDetector
//                 handleWidth
//                 render={({ width }) => (
//                     <Fragment>
//                         <div className={cx(
//                             "app-container app-theme-" + colorScheme,
//                             {'fixed-header': enableFixedHeader},
//                             {'fixed-sidebar': enableFixedSidebar || width < 1250},
//                             {'fixed-footer': enableFixedFooter},
//                             {'closed-sidebar': enableClosedSidebar || width < 1250},
//                             {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
//                             {'sidebar-mobile-open': enableMobileMenu},
//                             {'body-tabs-shadow-btn': enablePageTabsAlt},
//                         )}>
//                             <AppMain />
//                         </div>
//                     </Fragment>
//                 )}
//             />
//         )
//     }
// }

// const mapStateToProp = state => ({
//     colorScheme: state.ThemeOptions.colorScheme,
//     enableFixedHeader: state.ThemeOptions.enableFixedHeader,
//     enableMobileMenu: state.ThemeOptions.enableMobileMenu,
//     enableFixedFooter: state.ThemeOptions.enableFixedFooter,
//     enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
//     enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
//     enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

// });

// export default withRouter(connect(mapStateToProp)(Main));

import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import ResizeDetector from "react-resize-detector";
import AppMain from "../../Layout/AppMain/appMain";
import { Modal, ModalBody } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Main = ({
  colorScheme,
  enableFixedHeader,
  enableFixedSidebar,
  enableFixedFooter,
  enableClosedSidebar,
  enableMobileMenu,
  enablePageTabsAlt,
}) => {
  console.log(
    "  colorScheme,    enableFixedHeader,    enableFixedSidebar,    enableFixedFooter,    enableClosedSidebar,    enableMobileMenu,    enablePageTabsAlt,",
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt
  );
  // const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const location = useLocation()
  // console.log("🚀 ~ location:", location)

  // useEffect(() => {
  //     const handleResize = () => {
  //         setWindowWidth(window.innerWidth);
  //     };

  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": enableClosedSidebar || width < 1250 },
              { "closed-sidebar-mobile": width < 1250 },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}
          >
            <AppMain />
          </div>
        </Fragment>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProps)(Main));
