
import * as io from "socket.io-client";
import configuration from '../Configuration/config';
import jwt from 'jwt-decode';

const config = new configuration();
var connected = 0;

export let socket = io.connect(config.socketUrl);


socket.on('status', response => {
    if (response && response.status) {
        if (response.status === 'connected') {
            connected = 1;

            userOnlineStatus();
            
            //joinSocketRoom(user.user_id);
        }
        else if (response.status === 'disconnected') {
            const token = localStorage.getItem('token');
    
            let user = jwt(token);
        
            connected = 0;
            
            socket = socket.connect(config.socketUrl);
            userOnlineStatus();
            joinSocketRoom(user.user_id);
        }
    }
});

setInterval(()=>{
    if(connected === 1) {
        socket.emit("ping_event")
    }
}, 40000);

socket.on("pong_event", (message)=>{
    console.log("pong_event_message ===>", message)
}); 

socket.on('joined', room => {
    console.log('successfully joined room ', room);
});

socket.on('leaved', room => {
    console.log('successfully leaved room ', room);
});

export function userOnlineStatus() {

    const token = localStorage.getItem('token');
    
    let user = jwt(token);

    if (user.roleId == 3) {
        socket.emit("online_status", { "parent_id": user.createdBy, "id": user.user_id, "isFree": true });
    }
}

function checkConnection() {
    console.log("checkConnection");
    if (!socket.connected) {
       
        console.log("checkConnection reconnect");
        const token = localStorage.getItem('token');
    
        let user = jwt(token);

        connected = 0;
        
        socket.connect(config.socketUrl);
        userOnlineStatus();
        joinSocketRoom(user.user_id);
    }
}

setInterval(checkConnection, 5000);

socket.on('disconnected', ()=> {
    // window.location.reload();


    const token = localStorage.getItem('token');
    
    let user = jwt(token);

    connected = 0;

    socket = socket.connect(config.socketUrl);
    userOnlineStatus();
    joinSocketRoom(user.user_id);
})


//tell socket.io to never give up :)
socket.on('error', function(){
    const token = localStorage.getItem('token');
    
    let user = jwt(token);

    connected = 0;
    
    socket.connect(config.socketUrl);
    userOnlineStatus();
    joinSocketRoom(user.user_id);
  });


export function joinSocketRoom(room) {

    userOnlineStatus();

    socket.emit('join_room', room);
}

export function leaveSocketRoom(room) {

    socket.emit('leave_room', room);
}

setInterval(() => {
    if (connected === 0) {
        window.location.reload();
    }
}, 15 * 60 * 1000);

