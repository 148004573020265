import { Component } from "react";
import Axios from "axios";
import Config from "../Configuration/config";

class AllApi extends Component {
  constructor(props) {
    super(props);
    this.config = new Config();
  }

  getAxiosCalls = (method, url, header) => {
    return Axios({
      method: method,
      url: this.config.apiUrl + url,
      headers: header,
    });
  };

  putAxiosCalls = (data, method, url, header) => {
    return Axios({
      method: method,
      url: this.config.apiUrl + url,
      data: data,
      headers: header,
    });
  };

  postAxiosCalls = (data, method, url, header) => {
    return Axios({
      method: method,
      url: this.config.apiUrl + url,
      data: data,
      headers: header,
    });
  };

  patchAxiosCalls = (method, url,  data, header) => {
    return Axios({
      method: method,
      url: this.config.apiUrl + url,  
      data: data,
      headers: header
    });
  };

  deleteAxiosCalls = (method, url, header) => {
    return Axios({
      method: method,
      url: this.config.apiUrl + url,
      headers: header,
    });
  };
}

export default AllApi;
