import React, { createContext, useState } from "react";

export const MachineUserContext = createContext();

const MachineUserContextProvider = ({ children }) => {
	
	const [userData, setUserData] = useState("");

	const updateState = (eml) => {
		setUserData(eml);
	}

	return ( <MachineUserContext.Provider value={{ userData, updateState }}>  
        	{children} 
   		</MachineUserContext.Provider> )

};

export default MachineUserContextProvider;