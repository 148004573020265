import './polyfills'
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import Loader from "react-loaders";

import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Pages/Main/main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";
import i18n from './i18n';
import MachineUserContextProvider from './context/machineUserContext';
const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(

        <Provider store={store}>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
            <MachineUserContextProvider>
                <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                    <Component />
                    </Suspense>
            </MachineUserContextProvider>

                </I18nextProvider>
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./Pages/Main/main', () => {
//         const NextApp = require('./Pages/Main/main').default;
//         renderApp(NextApp);
//     });
// }
serviceWorker.unregister();

